import * as fs from 'fs';
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { Utils } from '../../utils';
import './easycaseperson';
import '../dialog/message';
import { Postbox } from '../postbox/postbox';

class EasyCaseViewModel {
    clients: ko.ObservableArray<Person> = ko.observableArray([
        {
            personType: ko.observable("mandant"),
            isFirst: ko.observable(true),
            isShown: ko.observable(true),
            isMoreShown: ko.observable(false),
            isError: ko.observable(false),
            isExtended: ko.observable(false),
            data: createPersonObservable()
        }
    ]);
    defendants: ko.ObservableArray<Person> = ko.observableArray([
        {
            personType: ko.observable("gegner"),
            isFirst: ko.observable(true),
            isShown: ko.observable(true),
            isMoreShown: ko.observable(false),
            isError: ko.observable(false),
            isExtended: ko.observable(false),
            data: createPersonObservable()
        }
    ]);
    mandantenbegehren = ko.observable("");
    notizen = ko.observable("");
    sachverhalt = ko.observable("");
    fka = ko.observable("");
    fkaid = ko.observable("");
    registernummer = ko.observable("");
    currentDate = ko.observable(null);
    CurrentUser = ko.observable("");
    SelectedUser = ko.observable("");
    SelectedUserName = ko.observable("");
    referat = ko.observable("");
    collisionErrored = ko.observable(false);
    weitereSBType() {
        let obj = {
            ID: ko.observable(''),
            Name: ko.observable('')
        }
        return obj
    }
    instanceType() {
        let obj = {
            InstanceType: ko.observable(''),
            ExternalProgramId: ko.observable(''),
            ExternalUniqueId: ko.observable(''),
            CourtId: ko.observable(''),
            CourtCase: ko.observable(''),
            CourtCaseValue: ko.observable(''),
            Clerks: ko.observableArray([])
        }
        return obj
    }
    instanceClerkType() {
        let obj = {
            instanceId: ko.observable(0),
            ClerkId: ko.observable(''),
            ClerkName: ko.observable(''),
            actionHandlers: ko.observableArray([])
        }
        return obj
    }
    weitereSB = ko.observableArray([]);
    weitereSBCount = ko.observable(0);
    assignedInstances = ko.observableArray([]);
    assignedInstancesCount = ko.observable(0);
    rubrum = ko.observable("");
    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    infoMessage = ko.observable('');
    infoTitle = ko.observable('');
    modialAutoHide = ko.observable(false)
    modalHandleSelection = ko.observable();
    isLoaded = ko.observable(false);
    GerAdressen = ko.observableArray([]);
    FirstCourt = ko.observable('');
    CourtCaseID = ko.observable('');
    MandaCaseID = ko.observable('');
    AZPzbvm = ko.observable('');
    filter = ko.observable('');
    initialfocusinput = true;
    modalSelectedObject = ko.observable(null);
    RegNr = ko.observable('');
    setFrist = ko.observable(false);
    collision: ko.Observable<Collision> = ko.observable(CollisionOff);
    outlookAccounts = ko.observableArray([]);
    allReferate = ko.observableArray([]);
    allInstances = ko.observableArray([]);
    currentInstanceIndex = ko.observable(0);
    currentInstance = ko.observable();
    isDuplicate = ko.observable(false);

    closeCollision(): void {
        this.collision(CollisionOff);
    }

    async myCheckCollision() {
        let clients = this.clients();
        let defendants = this.defendants();
        let clientsPeople: string[] = [];
        let defendandtsPeople: string[] = [];
        let clientsCollisions = [];
        let defendantsCollisions = [];
        let RequiredFieldsLoaded = true;

        if (defendants.length === 1 && !defendants[0].data.Name1()) {
            this.defendants.remove(this.defendants()[0]);
        }

        if (ko.toJS(this.registernummer) === '') {
            this.setErrors('regnr', 'regnr', 0);
            RequiredFieldsLoaded = false;
        }

        if (ko.toJS(this.outlookAccounts).length > 0) {
            if (ko.toJS(this.fka) === '') {
                this.setErrors('fka', 'fka', 0);
                RequiredFieldsLoaded = false;
            }
        }

        if (ko.toJS(this.SelectedUser) === '') {
            this.setErrors('lp', 'lp', 0);
            RequiredFieldsLoaded = false;
        }

        for (let i = 0; i < ko.toJS(this.assignedInstances).length; i++) {
            if (ko.toJS(this.assignedInstances)[i].CourtId === "") {
                try {
                    $("#CourtId_" + i).addClass('has-error');
                    RequiredFieldsLoaded = false;
                }
                catch{ }
            }
            else {
                try {
                    $("#CourtId_" + i).removeClass('has-error');
                }
                catch{ }
            }
            if (ko.toJS(this.assignedInstances)[i].Clerks.length === 0) {
                $("#SBsInInstanceError_" + i).removeClass('hide');
                RequiredFieldsLoaded = false;
                
            }
            else {
                $("#SBsInInstanceError_" + i).addClass('hide');
            }
        }

        let isError = false;

        for (let i = 0; i < defendants.length; i++) {
            if (!defendants[i].data.Rechtsform()) {
                this.setErrors('defendant', 'rechtsform', i);
                RequiredFieldsLoaded = false;
            } else {
                defendants[i].isError(false);
            }


            if (!defendants[i].data.Name1()) {
                this.setErrors('defendant', 'last-name', i);
                RequiredFieldsLoaded = false;
            } else {
                defendants[i].isError(false);
                defendandtsPeople.push(ko.toJS(defendants[i].data.Name1));
            }
        }

        for (let i = 0; i < clients.length; i++) {
            if (!clients[i].data.Rechtsform()) {
                this.setErrors('client', 'rechtsform', i)
                RequiredFieldsLoaded = false;
            } else {
                clients[i].isError(false);
            }


            if (!clients[i].data.Name1()) {
                this.setErrors('client', 'last-name', i)
                RequiredFieldsLoaded = false;
            } else {
                clients[i].isError(false);
                clientsPeople.push(ko.toJS(clients[i].data.Name1));
            }
        }

        if (isError) return;

        if (RequiredFieldsLoaded) {

            this.collision(CollisionLoading);

            for (let lastName of defendandtsPeople) {
                let collisions = await RNSAPI.checkCollision(lastName)
                defendantsCollisions = defendantsCollisions.concat(collisions.Payload.Addresses[0]);
            }

            for (let lastName of clientsPeople) {
                let collisions = await RNSAPI.checkCollision(lastName)
                clientsCollisions = clientsCollisions.concat(collisions.Payload.Addresses[1]);
            }

            for (let client of clients) {
                for (let defen of defendants) {
                    if (client.data.Name1() === defen.data.Name1()) {
                        defendantsCollisions.push({
                            FirstName: defen.data.FirstName(),
                            Name1: defen.data.Name1(),
                            Address: "",
                            ZipCode: "",
                            CityName: "",
                        });
                    }
                }
            }

            if (this.defendants().length == 0)
                this.rubrum(createRubrum(this.clients()[0].data.Name1(), null));
            else
                this.rubrum(createRubrum(this.clients()[0].data.Name1(), this.defendants()[0].data.Name1()));

            this.collision({
                kind: "CollisionLoaded",
                clients: ko.observableArray(clientsCollisions),
                defendants: ko.observableArray(defendantsCollisions)
            });
        }
    }

    setErrors(prefix, type, i) {
        this.clients()[i].isShown(true);
        this.clients()[i].isMoreShown(true);
        this.clients()[i].isError(true);
        document.getElementById(`${prefix}-${type}-` + i).scrollIntoView()
        $(`#${prefix}-${type}-` + i).parent().addClass('has-error');
        this.collisionErrored(true)
        setTimeout(() => {
            this.collisionErrored(false)
        }, 10000);
    }

    async pickGeneric(title, keys, columns, data, callback) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
        this.modalHandleSelection(callback);
    };

    addPerson(personType) {
        let array = personType === 'mandant' ? this.clients : this.defendants;
        array.push({
            personType: ko.observable(personType),
            isFirst: ko.observable(false),
            isShown: ko.observable(true),
            isMoreShown: ko.observable(false),
            isError: ko.observable(false),
            isExtended: ko.observable(false),
            data: createPersonObservable()
        });
    }

    addSachbearbeiter() {
        this.weitereSB.push(ko.toJS(this.weitereSBType().ID));
        this.weitereSBCount(1);
    }

    removeSB(sb) {
        this.weitereSB.splice(sb, 1);
        this.weitereSBCount(this.weitereSB().length);
    }

    removeInstance(instance) {
        this.assignedInstances.splice(instance, 1);
        this.assignedInstancesCount(this.assignedInstances().length);
    }

    removeSBfromInstance(clerksList) {
        this.assignedInstances.splice(clerksList, 1);
        this.assignedInstancesCount(this.assignedInstances().length);
    }

    async addSBtoInstance(instance) {
        this.currentInstanceIndex(instance);
        Postbox.publisher().publish("", "ResetDialogFilterOption");
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric2("Verfahrensarbeiter wählen", ["Sachbearbeiter"], ["Verfahrensarbeiter"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => {
            let newSBinstance = this.instanceClerkType();
            newSBinstance.instanceId = instance;
            newSBinstance.ClerkId = selectedObject()["Sachbearbeiter_ID"];
            newSBinstance.ClerkName = selectedObject()["Sachbearbeiter"];
            newSBinstance.actionHandlers([
                {
                    'icon': 'trash-alt',
                    'name': 'Löschen',
                    'action': () => {
                        let sbsInInstance = ko.toJS(this.assignedInstances()[ko.toJS(newSBinstance.instanceId)].Clerks);
                        sbsInInstance = sbsInInstance.filter(a => a.ClerkId !== newSBinstance.ClerkId);
                        this.assignedInstances()[ko.toJS(newSBinstance.instanceId)].Clerks(sbsInInstance);
                    }
                }
            ]);
            this.assignedInstances()[ko.toJS(this.currentInstanceIndex)].Clerks.push(newSBinstance);
            this.assignedInstancesCount()[this.assignedInstances().length];
            let sb = this.weitereSBType();
            sb.ID = newSBinstance.ClerkId;
            sb.Name = newSBinstance.ClerkName;
            this.weitereSB().push(sb);
            console.log(ko.toJS(this.weitereSB))
            this.weitereSBCount(this.weitereSB().length);
        });
        $('#modal').modal('show');
    }

    deleteDefendant(index: number): void {
        if (window.confirm('Sind Sie sicher, dass Sie die Gegnerdaten löschen möchten?')) {
            this.defendants.remove(this.defendants()[index]);
        }
    }

    deleteClient(index: number): void {
        if (window.confirm('Wollen Sie den Mandanten entfernen.?')) {
            this.clients.remove(this.clients()[index]);
        }
    }
    isOpeningSBs = ko.observable(false);
    pickSachbearbeiter = async () => {
        Postbox.publisher().publish("", "ResetDialogFilterOption");
        this.isOpeningSBs(true);
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric2("Billing Partner wählen", ["Sachbearbeiter"], ["Billing Partner"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => {
            this.SelectedUser(selectedObject()["Sachbearbeiter_ID"]);
            this.SelectedUserName(selectedObject()["Sachbearbeiter"])
        });
        $('#modal').modal('show');
        this.isOpeningSBs(false);
    };

    pickSachbearbeiterForIndex = async (a) => {
        Postbox.publisher().publish("", "ResetDialogFilterOption");
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric2("Verfahrensarbeiter wählen", ["Sachbearbeiter"], ["Verfahrensarbeiter"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => {
            let sb = this.weitereSBType();
            sb.ID = selectedObject()["Sachbearbeiter_ID"];
            sb.Name = selectedObject()["Sachbearbeiter"];
            this.weitereSB.push(sb);
            this.weitereSBCount(this.weitereSB().length);
        });
        $('#modal').modal('show');
    };

    pickInstance = async () => {
        Postbox.publisher().publish("", "ResetDialogFilterOption");
        await RNSAPI.getInstances().then((response) => {
            let res = response as any;
            this.allInstances(res);

        }).catch((error) => { });

        let instances = ko.toJS(this.allInstances);

        this.pickGeneric2("Instanz wählen", ["Name"], ["Instanz"], instances);
        this.modalHandleSelection((selectedObject) => {
            let instanceObj = this.instanceType();
            instanceObj.InstanceType = selectedObject()["Name"];
            instanceObj.Clerks([]);
            this.assignedInstances.push(instanceObj);
            this.assignedInstancesCount(this.assignedInstances().length);
        });
        $('#modal').modal('show');
    };

    showSBs() {
        $("#collapseWSb").removeClass("collapse");
    }

    showGegner() {
        $("#collapseExampleThree").removeClass("collapse");
    }

    async pickGeneric2(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    async pickReferat() {
        Postbox.publisher().publish("", "ResetDialogFilterOption");
        const referate = ko.toJS(this.allReferate);
        this.pickGeneric2("Standort", ["Name", "Id"], ["Name", "Id"], referate);
        this.modalHandleSelection((selectedObject) => this.referat(selectedObject()["Name"]));
        $('#modal').modal('show');
    }

    async getReferate() {
        const referate = (await RNSAPI.getReferate()).Payload.Units;
        this.allReferate(referate);
    }

    async getReferateCode(value, type) {
        if (value !== "") {
            const key = type === 'Name' ? 'Id' : 'Name'
            const referate = (await RNSAPI.getReferate()).Payload.Units;
            const found = referate.filter(item => item[key] === value)[0]

            return found[type]
        }
        else
            return "";
    }

    modalPick() {
        if (this.modalHandleSelection !== null) this.modalHandleSelection()(this.modalSelectedObject);
        $('#gerichtsmodal').modal('hide');
    };

    modalSelect = (obj, event) => {

        if ($(event.target.parentElement).hasClass("active")) {
            this.modalPick();
        }
        else {
            $("#modalTable tr").removeClass("active");
            $(event.target.parentElement).addClass("active");
            this.modalSelectedObject(obj);
        }
    }

    focusinput() {
        if (ko.toJS(this.initialfocusinput.toString()) === "true") {
            document.getElementById("inputGer-filter").focus();
            this.initialfocusinput = false;
        }
    }

    async pickMultipleCoutAddress(index) {
        $('#gerichtsmodal').modal('show');
        this.isLoaded(false);
        if (this.GerAdressen.length === 0) {
            let gerichte = [];
            await RNSAPI.getAdressPagin(8, "").then((addresses) => {
                let addr = addresses as any;
                gerichte = addr;
            }).catch((error) => {
                console.log(error);
                gerichte = [];
            });

            this.GerAdressen(gerichte);
            this.isLoaded(true);
            let AllAddr = this.GerAdressen();
            Postbox.publisher().subscribe((Filter) => {
                this.GerAdressen(Filter);
                this.isLoaded(true);
            }, "ApplyFilterGer");
            this.focusinput();
            document.getElementById('inputGer-filter').oninput = function (f) {
                let theFilter = (<HTMLInputElement>document.getElementById('inputGer-filter')).value;
                let Filtered = [];
                if (theFilter.length > 2) {
                    for (let i = 0; i < AllAddr.length; i++) {
                        if ((AllAddr[i].Name + AllAddr[i].City + AllAddr[i].Keyword).toString().includes(theFilter)) {
                            Filtered.push(AllAddr[i])
                        }
                    }
                    Postbox.publisher().publish(Filtered, "ApplyFilterGer");
                }
                else {
                    Postbox.publisher().publish(AllAddr, "ApplyFilterGer");
                }

            }
        }
        else
            this.isLoaded(false);

        this.modalHandleSelection((selectedObject) => ko.toJS(this.assignedInstances()[index].CourtId(selectedObject()["Keyword"])));
    }

    async pickAddress1(Instance) {
        $('#gerichtsmodal').modal('show');
        this.isLoaded(false);
        if (this.GerAdressen.length === 0) {
            let gerichte = [];
            await RNSAPI.getAdressPagin(8, "").then((addresses) => {
                let addr = addresses as any;
                gerichte = addr;
            }).catch((error) => {
                console.log(error);
                gerichte = [];
            });
            this.GerAdressen(gerichte);
            this.isLoaded(true);
            let AllAddr = this.GerAdressen();
            Postbox.publisher().subscribe((Filter) => {
                this.GerAdressen(Filter);
                this.isLoaded(true);
            }, "ApplyFilterGer");
            this.focusinput();
            document.getElementById('inputGer-filter').oninput = function (f) {
                let theFilter = (<HTMLInputElement>document.getElementById('inputGer-filter')).value;
                let Filtered = [];
                if (theFilter.length > 2) {
                    for (let i = 0; i < AllAddr.length; i++) {
                        if ((AllAddr[i].Name + AllAddr[i].City + AllAddr[i].Keyword).toString().includes(theFilter)) {
                            Filtered.push(AllAddr[i])
                        }
                    }
                    Postbox.publisher().publish(Filtered, "ApplyFilterGer");
                }
                else {
                    Postbox.publisher().publish(AllAddr, "ApplyFilterGer");
                }

            }
        }
        else
            this.isLoaded(false);
        if (Instance === 1) {
            this.modalHandleSelection((selectedObject) => this.FirstCourt(selectedObject()["Keyword"]));
        }
    }

    async postData() {
        let caseData = { Registernummer: this.registernummer(), Rubrum: this.rubrum(), AktenZeichenGericht1: "",  AktenZeichenGericht2: "", AktenZeichenGericht3: "",};

        let addresses = [
            ko.toJS(this.clients).map(p => p.data),
            ko.toJS(this.defendants).map(p => p.data),
            [],
            [],
            [],
            [],
            [],
            [],
            []
        ];

        let inst = ko.toJS(this.assignedInstances);
        inst = inst.map((a) => {
            if(a.InstanceType === "1. Instanz")
                caseData.AktenZeichenGericht1 = a.CourtCase;
            if(a.InstanceType === "2. Instanz")
                caseData.AktenZeichenGericht2 = a.CourtCase;
            if(a.InstanceType === "3. Instanz")
                caseData.AktenZeichenGericht3 = a.CourtCase;
            if (a.CourtCaseValue === "")
                a.CourtCaseValue = "0";
            return a;
        })

        let data: any = {
            Akte: caseData,
            Addresses: addresses,
            ExtendedPersons: [],
            Instances: inst
        };

        data.Instances = inst;

        try {
            data.Akte.SachbearbeiterId = this.SelectedUser();
            data.Akte.Referat = await this.getReferateCode(this.referat(), 'Id') || this.referat();
            data.Akte.MandantCaseID = ko.toJS(this.MandaCaseID);
            data.Akte.KorrAnwaltCaseID = ko.toJS(this.AZPzbvm);
            data.Akte.WeitereSachbearbeiterRecords = [];
            data.Akte.DeadlineCalendarAccount = this.fka();
            data.Akte.DeadlineCalendarAccountId = this.fkaid();
            for (let sb of ko.toJS(this.weitereSB)) {
                let t = { SachbearbeitertId: sb.ID }
                data.Akte.WeitereSachbearbeiterRecords.push(t);
            }

            if (ko.toJS(this.checkdata(data))) {
                let result = await RNSAPI.createExtendedCase(data);

                if (result.Type === "CaseCreationError") {
                    this.suggestRegisterNumber();
                    this.infoTitle('')
                    this.infoTitle('Fehler')
                    this.infoMessage('');
                    if(result.Payload.Message.includes('vergeben'))
                        this.infoMessage('Fehler:' + result.Payload.Message)
                    else
                        this.infoMessage('Fehler: Die Akte konnte nicht angelegt werden.');
                    return
                }
                else {
                    this.RegNr(ko.toJS(result.Payload.CaseId));
                    this.registernummer(ko.toJS(result.Payload.CaseId));
                    //this.RegNr(ko.toJS(this.registernummer));

                    Postbox.publisher().publish(ko.toJS(this.registernummer), "SetCaseID");

                    setTimeout(() => {
                        $('#EasyCaseNewDeadlineDialogExt').modal('show');
                    }, 300);

                }
            }
        } catch (e) {
            let error = Utils.handleError(e);
            this.infoMessage('');
            this.infoMessage(error['code'] + ': ' + error['message']);
        }
    }

    toLongMessage = ko.observable("");

    checkdata = (data) => {
        this.toLongMessage("");
        if (data.Akte.Registernummer.length > 13) {
            this.toLongMessage("Die maximale Länge der Registernummer ist 13 Zeichen");
            return false;
        }

        if (data.Akte.AktenZeichenGericht1.length > 30) {
            this.toLongMessage("Die maximale Länge des AZ Gericht ist 30 Zeichen");
            return false;
        }

        if (data.Akte.MandantCaseID.length > 30) {
            this.toLongMessage("Die maximale Länge des AZ Mandant ist 30 Zeichen");
            return false;
        }

        if (data.Akte.Rubrum.length > 255) {
            this.toLongMessage("Die maximale Länge des Rubrums ist 100 Zeichen");
            return false;
        }

        return true;
    }

    pickFKA = async () => {
        Postbox.publisher().publish("", "ResetDialogFilterOption");
        let FKA = ko.toJS(this.outlookAccounts());
        this.pickGeneric2("Fristenkalender wählen", ["UserLogin"], ["Fristenkalender"], FKA);
        this.modalHandleSelection((selectedObject) => {
            this.fka(ko.toJS(selectedObject()["UserLogin"]))
            this.fkaid(ko.toJS(selectedObject()["OutlookAccountID"]))
        });
        $('#modal').modal('show');
    };

    getFKAs = async () => {
        let FKA = (await RNSAPI.getOutlook()).Payload.OutlookAccounts;
        FKA.sort((a, b) => {
            if (a.UserLogin < b.UserLogin) { return -1; }
            if (a.UserLogin > b.UserLogin) { return 1; }
            return 0;
        });
        this.outlookAccounts(FKA);
    }

    async setDuplicateCaseData(caseToDuplicate){
        let duplicateCase = await (await RNSAPI.getExtendedCase(caseToDuplicate))
        if(duplicateCase.Type === "ExtendedCaseResult")    
        {
            this.isDuplicate(true);
            let duplicase = duplicateCase.Payload;
            
            let sachbearbeiter = (await RNSAPI.getLawFirmUser(duplicase.Akte.SachbearbeiterId)).Payload.User;
            
            this.SelectedUserName(sachbearbeiter.Vorname + ' ' + sachbearbeiter.Nachname);
            this.SelectedUser(duplicase.Akte.SachbearbeiterId);

            // if(duplicase.Akte.MandantCaseID !== null){
            //     this.MandaCaseID(duplicase.Akte.MandantCaseID);
            // }
            
            // if(duplicase.Akte.KorrAnwaltCaseID !== null){
            //     this.AZPzbvm(duplicase.Akte.KorrAnwaltCaseID);
            // }

            if(duplicase.Akte.DeadlineCalendarAccount !== null){
                this.fka(duplicase.Akte.DeadlineCalendarAccount);
            }

            if(duplicase.Akte.DeadlineCalendarAccountId !== null){
                this.fkaid(duplicase.Akte.DeadlineCalendarAccountId);
            }
            
            this.referat(await this.getReferateCode(duplicase.Akte.Referat, 'Name'));

            if(duplicase.Instances.length > 0)
            {
                for(let j =0; j<duplicase.Instances.length; j++){
                    let instanceObj = this.instanceType();
                    instanceObj.InstanceType = duplicase.Instances[j].InstanceType;
                    //instanceObj.CourtCase = duplicase.Instances[0].CourtCase;
                    instanceObj.CourtCaseValue = duplicase.Instances[j].CourtCaseValue;
                    instanceObj.CourtId(duplicase.Instances[j].CourtId);                
                    instanceObj.Clerks([]);
                    this.assignedInstances.push(instanceObj);
                    this.assignedInstancesCount(this.assignedInstances().length);
                    if(duplicase.Instances[j].Clerks.length>0)
                    {
                        for(let i = 0; i< duplicase.Instances[j].Clerks.length; i++)
                        {
                            let newSBinstance = this.instanceClerkType();
                            newSBinstance.ClerkId = duplicase.Instances[j].Clerks[i].ClerkId;
                            newSBinstance.ClerkName = duplicase.Instances[j].Clerks[i].Name;
                            newSBinstance.actionHandlers([
                                {
                                    'icon': 'trash-alt',
                                    'name': 'Löschen',
                                    'action': () => {
                                        let sbsInInstance = ko.toJS(this.assignedInstances()[j].Clerks);
                                        sbsInInstance = sbsInInstance.filter(a => a.ClerkId !== newSBinstance.ClerkId);
                                        this.assignedInstances()[j].Clerks(sbsInInstance);
                                    }
                                }
                            ]);
                            this.assignedInstances()[j].Clerks.push(newSBinstance);
                            this.assignedInstancesCount()[this.assignedInstances().length];
                            let sb = this.weitereSBType();
                            sb.ID = newSBinstance.ClerkId;
                            sb.Name = newSBinstance.ClerkName;
                            this.weitereSB().push(sb);
                            this.weitereSBCount(this.weitereSB().length);
                        }
                    }
                }
            }

            if(duplicase.Addresses[0].length>0)
            {
                for(let i =0; i<duplicase.Addresses[0].length; i++)
                {
                    if(i > 0)
                        this.addPerson("mandant")
                    for (let key in this.clients()[i].data) {
                        if (key === "Keyword") {
                            this.clients()[i].data["Keyword"] = ko.observable(duplicase.Addresses[0][i]["Keyword"]);
                        } else if (typeof (this.clients()[i].data[key]) === 'function') {
                            this.clients()[i].data[key](duplicase.Addresses[0][i][key]);
                        } else {
                            this.clients()[i].data[key] = duplicase.Addresses[0][i][key];
                        }
                    }
                }
            }

            if(duplicase.Addresses[1].length>0)
            {
                this.showGegner();
                for(let i =0; i<duplicase.Addresses[1].length; i++)
                {
                    if(i > 0)
                        this.addPerson("gegner")
                    for (let key in this.defendants()[i].data) {
                        if (key === "Keyword") {
                            this.defendants()[i].data["Keyword"] = ko.observable(duplicase.Addresses[1][i]["Keyword"]);
                        } else if (typeof (this.defendants()[i].data[key]) === 'function') {
                            this.defendants()[i].data[key](duplicase.Addresses[1][i][key]);
                        } else {
                            this.defendants()[i].data[key] = duplicase.Addresses[1][i][key];
                        }
                    }
                }
            }

            //Rubrum setzen
            //this.rubrum(duplicase.Akte.Rubrum)
        }
        else
            this.isDuplicate(false);  
    }

    constructor(params) {
        params = params || {};

        var mb = document.getElementsByClassName("modal-backdrop")
        for (let i = 0; i < mb.length; i++)
            mb[i].remove();

        this.suggestRegisterNumber();
        this.setCurrentData();
        this.getFKAs();
        this.getReferate();

        if(params.caseId){
            this.setDuplicateCaseData(params.caseId);
        }

        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        })
    }
    async setCurrentData() {
        this.CurrentUser(RNSAPI.User().username);
        var Heute = new Date();
        var TheDay = Heute.getDate();
        var TheMonth = Heute.getMonth() + 1;
        var TheYear = Heute.getFullYear();
        var Heutee = "";

        if (TheDay < 10) {
            Heutee += "0" + TheDay;
        } else {
            Heutee += TheDay;
        }

        if (TheMonth < 10) {
            Heutee += '.0' + TheMonth;
        } else {
            Heutee += '.' + TheMonth
        }

        Heutee += '.' + TheYear;

        this.currentDate(Heutee);
    }

    async suggestRegisterNumber() {

        let xregisternummer = (await RNSAPI.suggestCaseId()).Payload.CaseId;
        this.registernummer(xregisternummer);
    }

    removeClient(index) {
        if (window.confirm('Sind Sie sicher, dass die Gegnerdaten löschen möchten?')) {
            this.deleteClient(index)
        }
    }
}

function createRubrum(clientName: string, defendantName: string): string {
    if (clientName && defendantName)
        return (defendantName + " ./. " + clientName).substring(0, 64);
    else
        return clientName.substring(0, 64)
}

function isSamePerson(localPerson: any, serverPerson: any): boolean {
    return (localPerson.data.Name1() + localPerson.data.FirstName()).substring(0, 20) === serverPerson.Keyword;
}


function createPersonObservable(): any {
    let obj = {
        FirstName: ko.observable(null),
        Name1: ko.observable(null),
        Rechtsform: ko.observable(null),
        Address: ko.observable(null),
        ZipCode: ko.observable(null),
        CityName: ko.observable(null),
        EmailAddress: ko.observable(null),
        Phone1: ko.observable('+49'),
        Fax: ko.observable(null),
        MobilePhone: ko.observable(null),
        AnredeBriefkopf: ko.observable(null),
        AnredeDirekt: ko.observable(null),
        BirthDate: ko.observable(null),
        Adresszusatz: ko.observable(null),
        edit: ko.observable(true),
        IsSelected: ko.observable(false)
    };
    obj["Keyword"] = ko.computed(() =>
        (obj.Name1() || '') + (obj.FirstName() || ''));

    return obj;
}

export interface Person {
    personType: any,
    isFirst: any,
    isShown: any,
    isMoreShown: any,
    isError: any,
    isExtended: any,
    data: any,
}

type Collision = CollisionOff | CollisionLoading | CollisionLoaded;

interface CollisionOff {
    kind: "CollisionOff";
}

let CollisionOff: CollisionOff = { kind: "CollisionOff" };

interface CollisionLoading {
    kind: "CollisionLoading";
}

let CollisionLoading: CollisionLoading = { kind: "CollisionLoading" };

interface CollisionLoaded {
    kind: "CollisionLoaded";
    clients: any;
    defendants: any;
}

let html = fs.readFileSync(__dirname + '/easycase.html', 'utf8');

ko.components.register("easycase-view", {
    viewModel: EasyCaseViewModel,
    template: html
});
